@import url('https://fonts.cdnfonts.com/css/audrey-tatum');

:root {
  --animation-duration: 3s; /* Initial animation duration */
}

#container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: scale(0.7) translate(-80%, -70%);
 /* animation: rotate-animation calc(var(--animation-duration) / 2) infinite;*/
}
#fox {
  position: relative;
  width: 1080px;
  height:1024px;
}

#eyes {
  position: absolute;
  top: 50%; /* Adjust to position vertically */
  left: 50%; /* Adjust to position horizontally */
  transform: translate(-35%, -80%); /* Center the image */
  width: 482px; /* Set the width of the child image */
  height: 227px; /* Set the height of the child image */
  animation: blink-animation var(--animation-duration) infinite;  
}

#caption {
  position:absolute;
  top:50%;
  left:50%;
  width: 360px;
  height: 80px;
  transform: translate(-130px, 160px);
  text-align: center;
  word-wrap:break-word;
}

#caption h1 {
  text-transform: uppercase;
  font-family: 'Audrey Tatum', sans-serif;
}

#filling {
  position:fixed;
  top:50%;
  left:50%;
  width: 360px;
  height: 100px;
  font-size: 30px;  
  transform: translate(-120px, 240px);
  text-align: center;
  word-wrap:break-word;
}

@keyframes  blink-animation {
  0% { height: 227px; }
  90% { height: 227px; }
  91% { height: 2px;}
  93% { height: 2px;}
  94% { height: 227px; }
  100% { height: 227px; }
}

/* @keyframes  rotate-animation {
  0%    { transform: scale(0.7) translate(-80%, -80%) rotateY(0deg); }
  70%   { transform: scale(0.7) translate(-80%, -80%) rotate3d(1,1,0,3deg); }
  100%  { transform: scale(0.7) translate(-80%, -80%) rotateY(0deg); }
} */

/* Media queries for different screen sizes */
@media screen and (max-width: 768px) and (min-width: 481px) {
  #container {
    transform: scale(0.5) translate(-100%, -100%);
  }
}

@media screen and (max-width: 480px) {
  #container {
    transform: scale(0.38) translate(-132%, -130%);
  }
}
